import { Controller } from "stimulus"

import Flickity from "flickity"
import imagesloaded from "flickity-imagesloaded"
import asNavFor from "flickity-as-nav-for"

export default class extends Controller {
	initialize() {
		const steps = document.querySelector(".steps")
		const slider = document.querySelector(".carousel")

		let sliderflkty = new Flickity(slider, {
			asNavFor: ".steps",
			pageDots: false,
			imagesLoaded: true,
			lazyLoad: 2,
			prevNextButtons: false,
		})

		let stepsflkty = new Flickity(steps, {
			pageDots: true,
			prevNextButtons: false,
			autoPlay: true,
		})
	}
}
