import { Controller } from "stimulus"
import Rails from "rails-ujs"
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"

export default class extends Controller {
	static targets = ["start", "end"]

	connect() {
		let self = this

		this.startPicker = flatpickr(this.startTarget, {
			enableTime: true,
			dateFormat: "d/m/Y H:i",
			minDate: new Date().fp_incr(1), //tomorrow
			time_24hr: true,
			onChange: function(selectedDates, dateStr, instance) {
				self.dateChanged(self.startTarget)
			},
		})

		this.endPicker = flatpickr(this.endTarget, {
			enableTime: true,
			dateFormat: "d/m/Y H:i",
			time_24hr: true,
			onChange: function(selectedDates, dateStr, instance) {
				self.dateChanged(self.endTarget)
			},
		})
	}

	dateChanged(element) {
		if (element == this.startTarget) {
			this.endPicker.set("minDate", this.startTarget.value)
		}

		this.setTime()
		// let url =
		//     window.location.pathname + '/fleet' +
		//     `?pickup_date=${this.startTarget.value}&dropoff_date=${this.endTarget.value}`
		// Rails.ajax({
		//     url,
		//     dataType: 'js',
		//     type: 'GET'
		// });
	}

	setTime() {
		if (this.startTarget.value) {
			this.pickupTime = this.startTarget.value.split(" ").pop()
		}

		if (this.endTarget.value) {
			this.dropoffTime = this.endTarget.value.split(" ").pop()
		}
	}

	set pickupTime(time) {
		document.getElementById("rental_pickup_time").value = time
	}

	set dropoffTime(time) {
		document.getElementById("rental_dropoff_time").value = time
	}
}
