import { Controller } from "stimulus"

import Flickity from "flickity"
import imagesloaded from "flickity-imagesloaded"

export default class extends Controller {
	initialize() {
		var carsList = document.querySelector(".cars-list")

		let carsListflkty = new Flickity(carsList, {
			pageDots: false,
			initialIndex: 2,
			selectedAttraction: 0.01,
			friction: 0.15,
			wrapAround: true,
			imagesLoaded: true,
		})

		const slider = document.querySelector(".agencies")

		const sliderflkty = new Flickity(slider, {
			pageDots: false,
			contain: true,
			imagesLoaded: true
		})
	}
}
