import { ApplicationController } from "utils/application-controller"

import Rails from "rails-ujs"

export default class extends ApplicationController {
	initialize() {
		this.hookQtyButtons()
		this.hookOptions()
		this.hookSwitch()
		this.hookSubmitButton()
	}

	hookSubmitButton() {
		let self = this
		document.querySelectorAll("#submitConfig").forEach((btn) => {
			btn.addEventListener("click", (e) => {
				e.preventDefault()
				btn.classList.add("is-loading")
				btn.disabled = true
				// Find configuration form if any
				if (self.form) {
					self.form.action = self.form.dataset.target
					self.refreshForm()
				}
			})
		})
	}

	hookQtyButtons() {
		let self = this
		document.querySelectorAll(".count-input-dec").forEach((dec) => {
			dec.addEventListener("click", (e) => {
				const countInput = e.target.nextElementSibling
				let currentValue = parseInt(countInput.value)
				if (currentValue === 1) return
				self.updateQuantity(countInput, currentValue - 1)
			})
		})

		document.querySelectorAll(".count-input-inc").forEach((dec) => {
			dec.addEventListener("click", (e) => {
				const countInput = e.target.previousElementSibling
				let currentValue = parseInt(countInput.value)
				if (currentValue >= 2) return
				self.updateQuantity(countInput, currentValue + 1)
			})
		})
	}

	hookOptions() {
		let self = this
		document.querySelectorAll(".tags.options .tag").forEach((option) => {
			option.addEventListener("click", (e) => {
				document
					.querySelectorAll(".tags.options .tag.selected")
					.forEach((prev) => {
						prev.classList.toggle("selected")
					})
				option.classList.toggle("selected")
				document.getElementById("addonOption").value = option.text
				self.refreshForm()
			})
		})
	}

	hookSwitch() {
		let self = this
		document.querySelectorAll(".addon-switch input").forEach((seat_switch) => {
			self.toggleAddonQuantity(seat_switch)

			if (seat_switch.classList.contains("quantifiable")) {
				self.toggleSeatQtyControl(seat_switch)
			}

			seat_switch.addEventListener("change", (event) => {
				self.toggleAddonQuantity(seat_switch)
				self.refreshForm()
			})
		})

		document
			.querySelectorAll('.kilometers input[type="range"]')
			.forEach((km_switch) => {
				self.toggleKmPackage(km_switch)
				km_switch.addEventListener("change", (event) => {
					self.toggleKmPackage(event.target)
					self.refreshForm()
				})
			})
	}

	toggleKmPackage(kmSwitch) {
		const kmInput = document.getElementById("rental_km_package")
		if (kmSwitch.value > 0) kmInput.value = "daily_200"
		else kmInput.value = "daily_100"
	}

	toggleAddonQuantity(addonSwitch) {
		const qtyControl = addonSwitch.parentNode.parentNode.querySelector(
			"[name$='[quantity]']"
		)
		if (addonSwitch.checked) qtyControl.value = 1
		else qtyControl.value = 0

		if (addonSwitch.classList.contains("quantifiable")) {
			this.toggleSeatQtyControl(addonSwitch)
		}
	}

	toggleSeatQtyControl(seat_switch) {
		const qtyControl = seat_switch.parentElement.nextElementSibling
		if (seat_switch.checked) qtyControl.classList.remove("hide-control")
		else qtyControl.classList.add("hide-control")
	}

	toggleOptionsControl(addon_switch) {
		const qtyControl = addon_switch.parentElement.nextElementSibling
		if (seat_switch.checked) qtyControl.classList.remove("hide-control")
		else qtyControl.classList.add("hide-control")
	}

	updateQuantity(countInput, currentValue) {
		if (countInput.readOnly) {
			return
		}
		countInput.value = currentValue
		const qtyControl = countInput.parentNode.parentNode.querySelector(
			"[name$='[quantity]']"
		)
		qtyControl.value = currentValue
		this.refreshForm()
	}

	refreshForm() {
		Rails.fire(this.form, "submit")
	}

	get form() {
		return document.forms.rental_config
	}
}
