import { Controller } from "stimulus"

import init_maps_api from "utils/google_maps_api"

import { clearElements } from "utils/helpers"

export default class extends Controller {
	initialize() {
		document.addEventListener("maps_api:ready", this.on_maps_ready, {
			once: true,
		})
	}

	on_maps_ready = () => {
		this.maps_ready = true
		this.setupInput()
	}

	setupInput() {
		var inputs = document.querySelectorAll(".area-autocomplete")
		var options = {
			componentRestrictions: {
				country: ["fr", "mc", "es", "it", "de", "ch"],
			},
			types: ["(regions)"],
		}
		let self = this
		inputs.forEach(function(input) {
			let gPlace = new google.maps.places.Autocomplete(input, options)
			input.addEventListener("keydown", (event) => {
				if (event.keyCode === 13) {
					event.preventDefault()
					return false
				}
			})
			google.maps.event.addListener(gPlace, "place_changed", function() {
				// Clear input text
				input.value = ""
				let place = gPlace.getPlace()
				// Make sure we're only addign the place once
				if (self.isInPage(place.place_id)) {
					return
				}
				let location = self.locationFromPlace(place)
				self.appendLocationFields(input, location, place.place_id)
				self.insertArea(place)
			})
		})
	}

	appendLocationFields(input, location, id) {
		let nextIndex = this.tagsCount
		let target = input.dataset.target
		for (var key in location) {
			var locField = document.createElement("input")
			locField.setAttribute("type", "hidden")
			locField.setAttribute(
				"name",
				target + "[" + nextIndex + "]" + "[" + key + "]"
			)
			locField.setAttribute("value", location[key])
			locField.classList.add(id)
			input.parentNode.appendChild(locField)
		}
	}

	insertArea(place) {
		let tag = document.createElement("span")
		tag.innerHTML = `${place.formatted_address}<a class="delete"></a>`
		tag.classList.add(place.place_id, "tag", "is-dark")
		document.querySelector("div .areas").appendChild(tag)
		let className = place.place_id
		let span = document.querySelector(`span[class~="${className}"]`)
		span.querySelector("a").addEventListener("click", function() {
			span.parentNode.removeChild(span)
			clearElements(className)
		})
	}

	locationFromPlace(place) {
		let ne = place.geometry.viewport.getNorthEast()
		let sw = place.geometry.viewport.getSouthWest()
		var location = {
			latitude: place.geometry.location.lat(),
			longitude: place.geometry.location.lng(),
			ne_latitude: ne.lat(),
			ne_longitude: ne.lng(),
			sw_latitude: sw.lat(),
			sw_longitude: sw.lng(),
		}

		for (var comp in place.address_components) {
			for (var type in place.address_components[comp].types) {
				if (place.address_components[comp].types[type] == "locality")
					location.city = place.address_components[comp].long_name
				if (
					!location.city &&
					place.address_components[comp].types[type] ==
						"administrative_area_level_2"
				)
					location.city = place.address_components[comp].long_name
				if (place.address_components[comp].types[type] == "country")
					location.country = place.address_components[comp].long_name
			}
		}

		if (location.city == undefined) {
			location.city = place.formatted_address
		}

		return location
	}

	get tagsCount() {
		return document.querySelector("div .areas").childElementCount
	}

	isInPage(id) {
		let className = `[class="${id}"]`
		return document.body.contains(document.querySelector(className))
	}
}
