import {
    Controller
} from "stimulus"

import init_maps_api from "utils/google_maps_api"

export default class extends Controller {

    initialize() {
        document.addEventListener("maps_api:ready", this.on_maps_ready, {
            once: true
        })
        init_maps_api()
    }

    on_maps_ready = () => {
        this.maps_ready = true
        console.log("on_maps_ready")
        this.setupMap()
    }


    setupMap() {
        const styles = [{
                "featureType": "administrative",
                "elementType": "all",
                "stylers": [{
                    "saturation": "-100"
                }]
            },
            {
                "featureType": "administrative.province",
                "elementType": "all",
                "stylers": [{
                    "visibility": "off"
                }]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [{
                        "saturation": -100
                    },
                    {
                        "lightness": 65
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [{
                        "saturation": -100
                    },
                    {
                        "lightness": "50"
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [{
                    "saturation": "-100"
                }]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [{
                    "visibility": "simplified"
                }]
            },
            {
                "featureType": "road.arterial",
                "elementType": "all",
                "stylers": [{
                    "lightness": "30"
                }]
            },
            {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [{
                    "lightness": "40"
                }]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [{
                        "saturation": -100
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [{
                        "hue": "#ffff00"
                    },
                    {
                        "lightness": -25
                    },
                    {
                        "saturation": -97
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels",
                "stylers": [{
                        "lightness": -25
                    },
                    {
                        "saturation": -100
                    }
                ]
            }
        ];
        let pickup = this.data.get("pickup").split(/, ?/)
        let dropoff = this.data.get("dropoff").split(/, ?/)

        const pickUpCord = new google.maps.LatLng(parseFloat(pickup[0]), parseFloat(pickup[1]));
        const dropOffCord = new google.maps.LatLng(parseFloat(dropoff[0]), parseFloat(dropoff[1]));

        let map = new google.maps.Map(document.getElementById('map'), {
            zoom: 5,
            styles,
            disableDefaultUI: true,
            zoomControl: true
        });

        let map_bounds = new google.maps.LatLngBounds()

        map_bounds.extend(pickUpCord)
        map_bounds.extend(dropOffCord)

        map.fitBounds(map_bounds)
        map.setCenter(map_bounds.getCenter())

        let pickUpMarker = new google.maps.Marker({
            position: pickUpCord,
            map: map,
            icon: {
                url: "/images/ic-place.svg"
            }
        });
        let dropOffMarker = new google.maps.Marker({
            position: dropOffCord,
            map: map,
            icon: {
                url: "/images/ic-place.svg"
            }
        });

        let pickUpCircle = new google.maps.Circle({
            strokeColor: '#00ccff',
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: '#00ccff',
            fillOpacity: 0.22,
            map: map,
            center: pickUpCord,
            radius: 200
        });

        let dropOffCircle = new google.maps.Circle({
            strokeColor: 'black',
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: 'black',
            fillOpacity: 0.22,
            map: map,
            center: dropOffCord,
            radius: 200
        });

    }
}