import { Controller } from "stimulus"
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"

export default class extends Controller {
	static targets = ["start", "end"]

	connect() {
		let self = this

		this.startPicker = flatpickr(this.startTarget, {
			enableTime: false,
			dateFormat: "d/m/Y",
			minDate: new Date().fp_incr(1), //tomorrow
			onChange: function(selectedDates, dateStr, instance) {
				self.dateChanged(self.startTarget)
			},
		})

		this.endPicker = flatpickr(this.endTarget, {
			enableTime: false,
			dateFormat: "d/m/Y",
			onChange: function(selectedDates, dateStr, instance) {
				self.dateChanged(self.endTarget)
			},
		})
	}

	dateChanged(element) {
		if (element == this.startTarget) {
			this.endPicker.set("minDate", this.startTarget.value)
		}
	}
}
